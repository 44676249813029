@tailwind base;
@tailwind components;
@tailwind utilities;

/* http://meyerweb.com/eric/tools/css/reset/
   v2.0 | 20110126
   License: none (public domain)
*/

html,
body {
  height: auto !important; /* Antd css overwrites this so we need to force it back. */
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.revert-font-size.ant-modal,
.revert-font-size .ant-modal-body {
  font-size: inherit;
}

.ant-popover-arrow {
  display: none !important;
}

.block-indent .ant-popover-inner {
  background-color: transparent !important;
  box-shadow: none !important;
}

.block-indent .ant-popover-inner-content {
  padding: 0 !important;
}

.ant-dropdown-menu {
  margin-top: -5px !important;
}

.icon-column {
  padding: 0px 8px !important;
}

.icon-column svg {
  display: block;
  margin-bottom: -6px;
  cursor: pointer;
  color: #999;
  transition: color 200ms;
}

.icon-column svg:hover {
  color: #666;
}

.ReactCollapse--collapse {
  transition: height 500ms;
}

.text-editor {
  flex: 1;
}

.ant-typography-edit-content {
  margin-bottom: 0 !important;
}

/* .ql-clipboard {
  position: fixed !important;
  display: none !important;
  left: 50% !important;
  top: 50% !important;
} */

.ql-container.ql-bubble:not(.ql-disabled) a::before,
.ql-container.ql-bubble:not(.ql-disabled) a::after {
  display: none !important;
}

.ql-bubble .ql-tooltip-editor input[type='text'] {
  color: #000 !important;
}

.ql-bubble .ql-tooltip {
  z-index: 10 !important;
}

.ql-bubble .ql-toolbar .ql-comment,
.ql-bubble .ql-toolbar .ql-action {
  margin-top: -7px;
}

.ql-bubble .ql-toolbar .ql-comment {
  width: 120px;
}

.ql-bubble .ql-toolbar .ql-comment:after {
  content: 'Add Comment';
  font-size: 16px;
}

.ql-bubble .ql-toolbar .ql-action {
  width: 100px;
}

.ql-bubble .ql-toolbar .ql-action:after {
  content: 'Add Action';
  font-size: 16px;
}

.ql-bubble .ql-toolbar button {
  color: #666 !important;
}

.ql-bubble .ql-toolbar button:hover {
  color: #000 !important;
}

.ql-bubble .ql-toolbar button .ql-stroke,
.ql-bubble .ql-toolbar button .ql-fill {
  stroke: #666 !important;
}

.ql-bubble .ql-toolbar button:hover .ql-stroke,
.ql-bubble .ql-toolbar button:hover .ql-fill {
  stroke: #000 !important;
}

.ql-tooltip-arrow {
  display: none !important;
}

.plain-text .ql-editor p {
  display: inline;
}

.ql-bubble .ql-tooltip {
  background-color: #fff !important;
  border-radius: 3px !important;
  border: 1px solid #ddd;
  color: #333 !important;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.05), 0 4px 8px rgba(0, 0, 0, 0.1);
  /* margin-top: -75px; */
}

.ql-editor.ql-blank::before {
  color: rgba(0, 0, 0, 0.3) !important;
  left: initial !important;
  right: initial !important;
}

.ql-container.ql-bubble:not(.ql-disabled) a {
  white-space: normal !important;
  word-break: break-word;
}

.react-datepicker-wrapper input {
  width: 200px;
  border: 1px solid #ddd;
  border-radius: 2px;
  padding: 4px;
}

.react-datepicker-popper {
  width: 400px;
}

.due .ant-picker-input > input {
  color: red;
  font-weight: bold;
}

.goodThings .content-edible:focus {
  outline: 2px auto #7e57c2;
}

.actions .content-edible:focus {
  outline: 2px auto #42a5f5;
}

.issues .content-edible:focus {
  outline: 2px auto #ff7043;
}

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif,
    'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
  font-size: 14px;
  color: rgba(0, 0, 0, 0.85);
  line-height: 1.5715;
}

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
body {
  line-height: 1;
}
ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
strong {
  font-weight: bold;
}
em {
  font-style: italic;
}
.ant-comment-content-detail ol {
  list-style-type: decimal;
  margin-left: 16px;
}
.ant-comment-content-detail ul,
.editor ul {
  list-style-type: disc;
  margin-left: 16px;
}

.ant-table-cell {
  background-color: white !important;
  font-size: 16px;
}

.ant-table-thead > tr > th,
.ant-table-tbody > tr > td,
.ant-table tfoot > tr > th,
.ant-table tfoot > tr > td {
  padding: 12px !important;
}

.editor blockquote {
  border-left: 2px solid #bbb;
  padding-left: 1em;
}

.editor a {
  text-decoration: underline;
}

.editor *:last-child {
  margin-bottom: 0;
}

.editor *:first-child {
  margin-top: 0;
}

.editor p {
  margin: 10px 0;
}

.editor h1,
.editor h2,
.editor h3 {
  font-weight: 500;
}

.ant-collapse > .ant-collapse-item > .ant-collapse-header {
  color: #666 !important;
}

.ant-collapse-content > .ant-collapse-content-box {
  padding: 0px !important;
}
